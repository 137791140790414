<template>
  <div>
    <b-row>
      <b-col>
        <b-card title="Detail Kuesioner">
          <b-form-group label-cols="4" label-cols-lg="2" label="NIP">
            <b-form-input v-model="lecturer.nip" disabled />
          </b-form-group>

          <b-form-group label-cols="4" label-cols-lg="2" label="Nama Dosen">
            <b-form-input v-model="lecturer.name" disabled />
          </b-form-group>

          <b-form-group
            label-cols="4"
            label-cols-lg="2"
            label="Blok/Matakuliah"
          >
            <b-form-input v-model="lecturer.subject" disabled />
          </b-form-group>

          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            class="float-right"
            @click="print"
            :disabled="statusPrint"
          >
            Print
            <b-spinner v-if="statusPrint" small />
          </b-button>

          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            class="float-right mr-1"
            @click="exportExcel"
            :disabled="statusExport"
          >
            Export Excel
            <b-spinner v-if="statusExport" small />
          </b-button>

          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            class="float-right mr-1"
            @click="$router.go(-1)"
          >
            Kembali
          </b-button>
        </b-card>

        <b-card>
          <b-table responsive :fields="columns" :items="rows">
            <template #cell(index)="data">
              {{ data.index + 1 }}
            </template>
          </b-table>
          
          <!-- <b-card style="background-color: #F0F2F5; margin-top: 38px;">
            <h6 class="font-bold" style="color: black;">Keterangan Skor</h6>
            <h6 style="color: black;">1 : Sangat Kurang (SK)</h6>
            <h6 style="color: black;">2 : Kurang (K)</h6>
            <h6 style="color: black;">3 : Cukup (C)</h6>
            <h6 style="color: black;">4 : Baik (B)</h6>
            <h6 style="color: black;">5 : Sangat Baik (SB)</h6>
          </b-card> -->

          <b-card style="background-color: #F0F2F5; margin-top: 38px;">
            <h6 class="font-bold" style="color: black;">Keterangan Skor</h6>
            <h6 style="color: black;">1 : Tidak Puas (TP)</h6>
            <h6 style="color: black;">2 : Kurang Puas (KP)</h6>
            <h6 style="color: black;">3 : Cukup Puas (CP)</h6>
            <h6 style="color: black;">4 : Puas (P)</h6>
            <h6 style="color: black;">5 : Sangat Puas (SP)</h6>
          </b-card>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BRow,
  BCol,
  BTable,
  BButton,
  BSpinner
} from "bootstrap-vue";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import FileDownload from "js-file-download";

export default {
  components: {
    BCard,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdownItem,
    BDropdown,
    BRow,
    BCol,
    BTable,
    BButton,
    ToastificationContent,
    BSpinner
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      statusPrint: false,
      statusExport: false,
      lecturer: {
        nip: null,
        name: null,
        subject: null,
      },
      printApi: "questionnaire-students/print",
      exportApi: "questionnaire-students/export",
      api: "questionnaire-students/detail",
      filter: {
        lecturerId: this.$route.params.lecturerId,
        blockId: this.$route.params.blockId,
      },
      rows: [],
      columns: [
        {
          label: "No",
          key: "index",
        },
        {
          label: "Aspek Penilaian",
          key: "questionName",
        },
        {
          label: "Skor",
          key: "skor",
        },
      ],
    };
  },
  async mounted() {
    const _ = this;
    const data = await _.getData();
    _.rows = data.data;
    _.lecturer.nip = data.nip;
    _.lecturer.name = data.lecturer;
    _.lecturer.subject = data.course;
  },
  methods: {
    async getData() {
      const _ = this;
      let data;
      await _.$axios.get(_.api, { params: this.filter }).then((res) => {
        data = res.data.data;
      });
      return data;
    },
    async print() {
      const _ = this;
      _.statusPrint = true;
      await _.$axios
        .get(`${_.printApi}`, {
          params: this.filter,
          responseType: "blob",
        })
        .then((res) => {
          _.statusPrint = false;
          const blob = new Blob([res.data], {
            type: "application/pdf",
          });
          const objectUrl = URL.createObjectURL(blob);
          window.open(objectUrl);
        })
        .catch((err) => {
          _.statusPrint = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Gagal Print Kuesioner",
              variant: "danger",
            },
          });
        });
    },
    async exportExcel() {
      const _ = this;
      _.statusExport = true;
      await _.$axios
        .get(`${_.exportApi}/${_.$route.params.lecturerId}/${_.$route.params.blockId}`, {
          responseType: "blob",
        })
        .then((res) => {
          _.statusExport = false;
          FileDownload(res.data, 'export-kuesioner-blok.xlsx');
        })
        .catch((err) => {
          _.statusExport = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Gagal Export Kuesioner",
              variant: "danger",
            },
          });
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style>
  .font-bold {
    font-weight: 900;
  }
</style>